import React, { useState, useEffect } from "react";
import { WelcomeMatTileClickable } from "../components/WelcomeMatTileClickable";
import { callApiWithToken } from "../fetch";
import { useMsal, useAccount } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { Spinner} from '@salesforce/design-system-react';


import {  protectedResources } from "../authConfig";


export function TemplateList(props) {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [templateData, setTemplateData] = useState(null);


  useEffect(() => {

      if (account && inProgress === "none" && !templateData) {
          instance.acquireTokenSilent({
              scopes: protectedResources.getTemplates.scopes,
              account: account
          }).then((response) => {
              callApiWithToken(response.accessToken, protectedResources.getTemplates.endpoint)
                  .then(response => setTemplateData(response));
          }).catch((error) => {
              // in case if silent token acquisition fails, fallback to an interactive method
              if (error instanceof InteractionRequiredAuthError) {
                  if (account && inProgress === "none") {
                      instance.acquireTokenPopup({
                          scopes: protectedResources.getTemplates.scopes,
                      }).then((response) => {
                          callApiWithToken(response.accessToken, protectedResources.getTemplates.endpoint)
                              .then(response => setTemplateData(response));
                      }).catch(error => console.error(error));
                  }
              }
          });
      }
  }, [account, inProgress, instance, templateData]);

  useEffect(() => {
    if(!props.selectedTemplate && templateData?.length > 0) {
      console.info('setting selected template to:', 0)
      props.setSelectedTemplate(templateData[0]);
    }
    }, [templateData, props]);


    return (
      <>
          { templateData ? <WelcomeMatTileClickable templateData={templateData} selectedTemplate={props.selectedTemplate} changeTemplate={props.setSelectedTemplate} /> 
          	:			<div style={{width:"416.5px", height:"600px"}}><Spinner
                          size="large"
                          variant="brand"
                          assistiveText={{ label: 'loading available templates...' }}
                      />  </div>}
      </>
  );

}

