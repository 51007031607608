import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./styles/App.css";
import { Dubber } from "./pages/dubber";
import { Submittal } from "./pages/submittal";



class App extends Component {
  render() {
    return (
      <Router>
        <Routes>
          <Route index element={<Dubber />} />
          <Route path="/submittal" element={<Submittal />} />
      </Routes>
  </Router>
            
    );
  }
}

export default App;
