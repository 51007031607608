import { MsalAuthenticationTemplate, useAccount, useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { InteractionRequiredAuthError, InteractionType } from "@azure/msal-browser";

import { loginRequest } from "../authConfig";
import { WelcomeMat } from "../components/WelcomeMat";

import { IconSettings } from '@salesforce/design-system-react';

import { protectedResources } from "../authConfig";
import { callApiWithToken, logSubmittalEvent, sendEmailWithToken } from "../fetch";




/**
 * The `MsalAuthenticationTemplate` component will render its children if a user is authenticated 
 * or attempt to sign a user in. Just provide it with the interaction type you would like to use 
 * (redirect or popup) and optionally a [request object](https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md)
 * to be passed to the login API, a component to display while authentication is in progress or a component to display if an error occurs. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export function Submittal(props) {
	const authRequest = {
		...loginRequest
	};

	const errorMsgAddon = ', please try again later. If this continues please contact the lab via the feedback details below.';

	const { instance, accounts, inProgress } = useMsal();
	const account = useAccount(accounts[0] || {});

	const [selectedTemplate, setSelectedTemplate] = useState(null);
	const [fileToBeDubbed, setFileToBeDubbed] = useState(null);
	const [dubbResponse, setDubbResponse] = useState(null);
	const [graphFile, setGraphFile] = useState(null);
	const [graphFileUrl, setGraphFileUrl] = useState(null);
	const [isDubbing, setIsDubbing] = useState(false);
	const [profileData, setProfileData] = useState(null);
	const [anError, setAnError] = useState(null);
	const [orderId, setOrderId] = useState(null);
	const [key, setKey] = useState(null);
	const [searchParams, setSearchParams] = useSearchParams();
	const handleClick = (event) => {
		console.log("save");
		var comments = document.getElementById("comments").value;
		var queryString = 'key=' + key + '&log=_LOGGED_COMMENTS_' + orderId + "_LOGGED_COMMENTS_" + comments;
		document.getElementById("commentsbtn").style.display = "none";
		document.getElementById("comments").value = "Thank you for feedback!";
		logSubmittal(instance, account, protectedResources, queryString);

		var log = searchParams.get("log").split("~");			
		var logObj = {"orderId" : log[0], "sentToEmail" : log[1], "sentFromEmail" : log[2], "sentToName" : log[3], "candidateName" : log[4], "opportunityName" : log[5], "judgment" : log[6]};
		logObj.opportunityName = logObj.opportunityName.replaceAll('|', '-');
		console.log("before logSubmittalOnComments + " + log);
		instance.acquireTokenSilent({
			scopes: protectedResources.getGraphProfile.scopes,
			account: account
		}).then((response) => {				
			//getOneDriveFileWithToken(response.accessToken, protectedResources.getGraphFile.endpoint, graphFile.id).then(response => setGraphFileUrl(response));
			callApiWithToken(response.accessToken, protectedResources.getGraphProfile.endpoint)
				.then((response) => 
				{
					setProfileData(response)
					sendEmail(instance, account, protectedResources, logObj, comments);
				}
				);
		}).catch((error) => {
			// in case if silent token acquisition fails, fallback to an interactive method
			if (error instanceof InteractionRequiredAuthError) {
				if (account && inProgress === "none") {
					instance.acquireTokenPopup({
						scopes: protectedResources.getGraphProfile.scopes,
					}).then((response) => {
						callApiWithToken(response.accessToken, protectedResources.getGraphProfile.endpoint)
							.then((response) => {
								setProfileData(response);
								sendEmail(instance, account, protectedResources, logObj, comments);
							});
					}).catch(error => console.log(error));
				}
			}
		});
	};
	


	useEffect(() => {
		
		if (account && inProgress === "none" && !profileData) {
			//first thing we want to do is log the decision by AM.. regardless of.. if they decide to bail on this auth below (which would allow for an email to be  sent to a recruiter )		
			//?key=AIzaSyBKT-Bv20gVPn1WxzJyVTctzvEcMO-u2es&log=801D50000043piOIAQ~cmangani@allegisgroup.com~cmangani@allegisgroup.com~Carl Manganillo~Sangeeta Sah~O-1490868 | Premier Respons | Customer Care/F | thanne~Accepted
			//801Hn00000FzsJ1IAJ~ksteller@teksystems.com~chldavis@teksystems.com~Kelsey Steller~Jayashree Pilli~O-4507772 - Bank Of America - scrum master - kstell~Deny-Other
			var log = searchParams.get("log").split("~");
			
			var logObj = {"orderId" : log[0], "sentToEmail" : log[1], "sentFromEmail" : log[2], "sentToName" : log[3], "candidateName" : log[4], "opportunityName" : log[5], "judgment" : log[6]};
			logObj.opportunityName = logObj.opportunityName.replaceAll('|', '-');
			console.log("before logSubmittal + " + log);
			setOrderId(log[0]);
			setKey(searchParams.get("key"));
			console.log("before logSubmittal2 + " + log);
			//801D50000043piOIAQ,cmangani@allegisgroup.com,cmangani@allegisgroup.com,Carl Manganillo,Sangeeta Sah,O-1490868 | Premier Respons | Customer Care/F | thanne,Accepted
			var queryString = 'key=' + searchParams.get("key") + '&' + searchParams.get("log");
			logSubmittal(instance, account, protectedResources, queryString);
			
			
			instance.acquireTokenSilent({
				scopes: protectedResources.getGraphProfile.scopes,
				account: account
			}).then((response) => {				
				//getOneDriveFileWithToken(response.accessToken, protectedResources.getGraphFile.endpoint, graphFile.id).then(response => setGraphFileUrl(response));
				callApiWithToken(response.accessToken, protectedResources.getGraphProfile.endpoint)
					.then((response) => 
					{
						setProfileData(response)
						sendEmail(instance, account, protectedResources, logObj);
					}
					);
			}).catch((error) => {
				// in case if silent token acquisition fails, fallback to an interactive method
				if (error instanceof InteractionRequiredAuthError) {
					if (account && inProgress === "none") {
						instance.acquireTokenPopup({
							scopes: protectedResources.getGraphProfile.scopes,
						}).then((response) => {
							callApiWithToken(response.accessToken, protectedResources.getGraphProfile.endpoint)
								.then((response) => {
									setProfileData(response);
									sendEmail(instance, account, protectedResources, logObj);
								});
						}).catch(error => console.log(error));
					}
				}
			});
		} else {
			console.info(profileData);
		}
	}, [account, inProgress, instance, profileData]);

	
	function sendEmail(instance, account, protectedResources, logObj, comments) {
		instance.acquireTokenSilent({
			scopes: protectedResources.sendSubmittalResponse.scopes,
			account: account
		}).then((response) => {
			
			let htmlContent = 'Submittal Package for ' + logObj.candidateName + ' for Opportunity: <strong>' + logObj.opportunityName + '</strong></br>viewed by: <strong>' + logObj.sentToName + '</strong></br></br> and the response what that it received the following adjudication: <strong>' + logObj.judgment + '</strong>';
			if (comments) {
				htmlContent+='<br/>Comments:<br/><br/>' + comments;
			}
			let body = {
				"message": {
				  "subject": "Submittal Package Viewed",
				  "body": {
					"contentType": "HTML",
					"content": htmlContent
				  },
				  "toRecipients": [
					{
					  "emailAddress": {
						"address": logObj.sentFromEmail
					  }
					}
				  ]
			  }
			};
			
			
			sendEmailWithToken(response.accessToken, protectedResources.sendSubmittalResponse.endpoint,JSON.stringify(body))
			.then(response => console.log(2345555))
			.catch((error) => {								
				console.log(error);
			});
		}).catch((error) => {
			console.log(error);
		});
	}
	function logSubmittal(instance, account, protectedResources, queryString) {

		instance.acquireTokenSilent({
			scopes: protectedResources.logSubmittal.scopes,
			account: account
		}).then((response) => {			
			var url = protectedResources.logSubmittal.endpoint.replace('/api/', '/');
			//logSubmittalEvent("https://smart-resume-staging.silverlabs.online/log?key=AIzaSyBKT-Bv20gVPn1WxzJyVTctzvEcMO-u2es&log=8017g000001FGt0AAG|cmangani@allegisgroup.com|cmangani@allegisgroup.com|Accepted")
			logSubmittalEvent(response.accessToken, url + "?" + queryString);
		}).catch((error)=> {

		})
		/*logSubmittalEvent("https://smart-resume-staging.silverlabs.online/log?key=AIzaSyBKT-Bv20gVPn1WxzJyVTctzvEcMO-u2es&log=8017g000001FGt0AAG|cmangani@allegisgroup.com|cmangani@allegisgroup.com|Accepted")
				.then(response => console.log("posted successfully"))
				.catch((error) => {
					//console.error(error);
					//setAnError(error + errorMsgAddon)
					//setIsDubbing(false);
				})*/
		
	}


	return (
		<MsalAuthenticationTemplate
			interactionType={InteractionType.Redirect}
			authenticationRequest={authRequest}
		>
			<IconSettings iconPath="/assets/icons">
				
				<WelcomeMat
					labels={{
						title: 'Please Leave Comment',
						description:
							'',//'Welcome to Allegis Group Innovation Labs Resume Dubbing Tool. Select a Template on the Right, then drag a Resume to the box below or click upload.',
					}}
					/*
					leftHandChildren={<div> <Mailto email="8bd5f2e2.ALLEGISCLOUD.onmicrosoft.com@amer.teams.ms" subject="Dubber Feedback">
					Questions and Feedback, email here.
				  </Mailto><br/><br/>
									<br/><a href="https://forms.office.com/r/Yyw0HZFMdJ" rel="noreferrer" target="_blank" >Tell us how we are doing, by filling in this survey.</a>	</div>	}
					*/
					leftHandChildren={<div class="slds-progress slds-progress_vertical">
					<textarea placeholder="Leave comments here" id="comments" rows="4" cols="50"></textarea>
					<button id="commentsbtn" style={{"backgroundColor" : "rgb(25, 118, 210)", "color" : "white"}} onClick={handleClick}>Save</button>
					</div>}
					rightHandChildren={<div class="slds-progress slds-progress_vertical"></div>}
				
/>
			</IconSettings>

		</MsalAuthenticationTemplate>
	)
};
