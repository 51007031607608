import React from "react";


export function WelcomeMat(props) {




    return (
        <section role="dialog" tabindex="-1" class="slds-modal slds-fade-in-open slds-modal_small" 
            style={{"background-image": "url('/assets/images/welcome-mat/bg-info@2x.png')",
                    "background-position": "bottom center",
                    "background-repeat": "no-repeat",
                    "background-size": "contain"}}
            aria-labelledby="welcome-mat-100-label" aria-describedby="welcome-mat-100-content" aria-modal="true">
            <div style={{"position": "absolute", "bottom": "0", "right": "0"}}>
                <img src="https://silver-labs.github.io/images/lab_color_stacked.svg" alt="Allegis Group Innovation Lab" width="300px" />
            </div>
            <div class="slds-modal__container">
                <div class="slds-modal__content" id="welcome-mat-100-content">
                    <div class="slds-welcome-mat">
                        <div class="slds-welcome-mat__content slds-grid">
                            <div class="slds-welcome-mat__info slds-size_1-of-2" style={{"background-image": "none", overflow: "unset"}}>
                                <div class="slds-welcome-mat__info-content">
                                    <h3 class="slds-welcome-mat__info-title" style={{fontSize: "1.9rem", minWidth: "max-content"}} id="welcome-mat-100-label">{props.labels.title || 'Put a Title Here'}</h3>
                                    {props.leftHandChildren}
                                </div>
                            </div>
                            <div class="slds-welcome-mat__tiles slds-size_1-of-2">
                                {props.rightHandChildren}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

}

