import React, { useState, useCallback } from "react";
import {useDropzone} from 'react-dropzone';
import {  IconSettings, Button } from '@salesforce/design-system-react';



export function FileDragNDrop(props) {

    const [isDragged, setIsDragged] = useState(false);

    

    const onDrop = useCallback((acceptedFiles, fileRejections) => {
        // Do something with the files
        setIsDragged(false);

        if(acceptedFiles && acceptedFiles.length > 0) {
          const file = acceptedFiles[0];
          props.setFileToBeDubbed(file);
        }

        
      }, [props])

    const { fileRejections, getRootProps, getInputProps, } = useDropzone({ onDrop, onDragEnter: () => setIsDragged(true), onDragLeave: () => setIsDragged(false), maxFiles:1, accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/pdf, text/plain, application/rtf'});

    const fileRejectionItems = fileRejections.map(({ file, errors  }) => { 
        return (
            errors.map(e => <div class="slds-form-element__help">{e.message}</div>)
        ) 
       });

    const dragClassName = isDragged ? 'slds-has-drag-over slds-file-selector__dropzone' : 'slds-file-selector__dropzone';

    return (
        <IconSettings iconPath="/assets/icons">

        <div class="slds-form-element">
        <div class="slds-form-element__control">
          <div class="slds-file-selector slds-file-selector_files">
            <div {...getRootProps({className: dragClassName, disabled: props.disabled })} style={{"border-color": "darkgrey"}}>
              <input {...getInputProps()} class="slds-file-selector__input slds-assistive-text" aria-labelledby="file-selector-primary-label file-selector-secondary-label" />
              <label class="slds-file-selector__body" for="file-upload-input-01" id="file-selector-secondary-label">
              <Button
						iconCategory="utility"
						iconName="download"
						iconPosition="left"
						label="Upload"
					/>
                <span class="slds-file-selector__text slds-medium-show">or Drop Resume</span>
              </label>
            </div>
          </div>
        </div>
        {fileRejectionItems}
      </div>
      </IconSettings>
    );

}

