import React, { Component }from "react";
import { Icon, WelcomeMatTile, IconSettings } from '@salesforce/design-system-react';
import { ReactSVG } from 'react-svg'



export class WelcomeMatTileClickable extends Component {
 

    onClick(selectedTemplateId){
        this.props.changeTemplate(selectedTemplateId)
    }

    render() {
        const tableRows = Object.entries(this.props.templateData).map((entry, index) => {

            let pic = null
            console.info(entry[1].icon );
            if(entry[1].icon && entry[1].icon !== '' ) {
                console.info(entry[1].icon );
                if (['TEK','AC','ACT','AERO'].includes(entry[1].icon)) {
                    pic = <ReactSVG src={"assets/logos/"+entry[1].icon.toLowerCase()+"-icon-logo.svg"}   beforeInjection={(svg) => {
                        svg.setAttribute('style', 'height: 52px; width: 52px')
                      }}/>
                } else {
                    pic = <Icon category="utility" name="animal_and_nature" style={{width:"52px", height:"52px"}} />
                }
            } else {
                pic = <Icon category="utility" name="animal_and_nature" style={{width:"52px", height:"52px"}}/>
            }

            return (
                <IconSettings iconPath="/assets/icons">
                <div onClick={this.onClick.bind(this,entry[1])} style={{paddingBottom:'16px'}}>
                <WelcomeMatTile
                    title={entry[1].displayName}
                    description={entry[1].description}
                    icon={pic}
                    id={entry[1].templateId}
                    href="#"
                    onClick={() =>{console.info('You clicked submit.');}}
                    isComplete={this.props.selectedTemplate?.templateId === entry[1].templateId}
                /></div></IconSettings>
            )
        });
    
        return (
            <>      
                {tableRows}
            </>
        );
    }
}

